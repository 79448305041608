import { FC } from "react";
import joinClassNames from "classnames";

import { Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  size?: Sizes;
  className?: string;
}

const RadioDot: FC<Props> = ({ className, size = "md" }) => (
  <div
    data-type="radio-dot"
    className={joinClassNames(classes.sizePresets, classes.radioDot, className)}
    data-size={size}
  />
);

export default RadioDot;
